ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;
  --width: auto; //350px;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}
