#newItemLine {
  ion-select {
    justify-content: left;
  }
}

#newShoppingTrip,
#newShoppingList {
  .ion-item-background {
    /*--background: #fff url("../theme/Pastle Bck.png") no-repeat center center /
      cover;*/
    --background: linear-gradient(to right, #ece4dd, #f3efe9, #dfd2c8);
  }
}

#shoppingTripItem,
#shoppingListItem {
  .ion-icon-tertiary {
    color: #516851;
  }
}

#subscriptionPage {
  .freemium-compare-grid {
    max-width: 50em;
    margin: 0 auto;
  }
}
