//  Force text-wrap on <ion-select>
ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}
// Force text-wrap on <ion-select-option>'s when shown in an Ionic Alert
ion-alert.select-alert {
  // These widths are pretty arbitrary, so you're use-case(s) could required different widths:
  --width: 25vw !important;
  --max-width: 66vw !important;
  //--max-width: 100% !important;
  //--width: 100%;

  .select-interface-option .alert-radio-label,
  .select-interface-option .alert-checkbox-label {
    white-space: normal !important;
  }
}
