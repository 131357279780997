#select-item-modal {
  height: 100%;
  overflow: "scroll";
  background-color: blue($color: #0026ff);
  --background-color: blue($color: #0408e7);

  ion-item {
    padding-top: 1px;
  }

  .select-item-class .modal-wrapper {
    background: rgb(221, 13, 13);
    overflow: "scroll";
  }

  .select-item-class {
    --background-color: blue($color: #0408e7);
    overflow: "scroll";
    --border-color: blue($color: #0026ff);
  }
}
